import React from "react";
import {useSelector} from "react-redux";

import classNames from "classnames";

import {useGetActiveRewardQuery} from "store/FreeItem/freeItem.api";
import {useAuthorization} from "hooks";
import {ModalsProvider} from "layouts";

import CosmeticViewTemplate from "components/molecules/CosmeticViewTemplate";
import {CountDownWithContainer} from "components/molecules";
import FreeItemModals from "./modals/FreeItemModals";
import Congratulation from "./Congratulation";
import Description from "./Description";
import ClaimButton from "./ClaimButton";

import {png} from "assets/png";
import "./style.scss"

const FreeItemSection = () => {
    const {userData} = useSelector(state => state.authV2.signIn)

    const {isAuth, accessToken} = useAuthorization()

    const userUuid = accessToken && isAuth ? userData.id : "null"
    const {
        data: rewardData,
        error: rewardError,
        isSuccess: rewardSuccess,
        isLoading: rewardLoading,
    } = useGetActiveRewardQuery(
        {uuid: userUuid},
        {skip: accessToken && !isAuth}
    );

    const isInProcess = rewardLoading || (rewardError ? false : rewardData === undefined)
    const isRewardClaimed = rewardSuccess && rewardData.is_claimed

    const rewardInventory = rewardData?.reward.inventory
    const inventoryImageUrl = rewardInventory?.image_url || png.RedQuestionMarkLarge
    const inventorySectionStyle = classNames("free-item-section", rewardInventory?.rarity ?? "ordinary")

    const inventoryTitle = rewardSuccess ? (isRewardClaimed ? "" : rewardInventory?.name) : rewardError && "???????????"
    const inventoryRarity = rewardSuccess ? (isRewardClaimed ? "" : rewardInventory?.rarity) : rewardError && "ordinary"
    const inventoryAttribute = rewardSuccess ? (isRewardClaimed ? "" : [{name: rewardInventory?.sub_category || "????"}]) : rewardError && [{name: "????"}]

    const isInventoryShareable = rewardSuccess ? !isRewardClaimed : false;

    return (
        <ModalsProvider>
            <section className={inventorySectionStyle}>
                <CosmeticViewTemplate
                    loading={isInProcess}
                    title={inventoryTitle}
                    img={inventoryImageUrl}
                    rarity={inventoryRarity}
                    attribute={inventoryAttribute}
                    isShare={isInventoryShareable}
                    classname="free-item-section_content container"
                >
                    {rewardSuccess && isRewardClaimed && <Congratulation/>}
                    <div className="free-item-section_content_deadline">
                        <CountDownWithContainer
                            day={true}
                            isReloadPage={true}
                            loading={isInProcess}
                            success={rewardSuccess}
                            date={rewardData?.reward.expiration_date}
                            text={rewardSuccess ? "This item expires in" : ""}
                            callBackReload={() => window.location.reload()}
                        />
                    </div>
                    <Description
                        isLoading={isInProcess}
                        rewardSuccess={rewardSuccess}
                        isRewardClaimed={isRewardClaimed}
                    />
                    {!isInProcess && !isRewardClaimed && rewardSuccess &&
                        <ClaimButton inventory={rewardInventory}/>}
                </CosmeticViewTemplate>
                <div className="overlay-bottom"/>
            </section>
            <FreeItemModals/>
        </ModalsProvider>
    )
}

export default FreeItemSection;