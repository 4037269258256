import {globalService} from "../Global/globalService";
import {createFetch} from "config/createFetch";

import {ENVIRONMENTS, errorHandler, SERVER} from "utils";
import {LOCKER, XSOLLA_STORE} from "../endpoint";

const getUserEligibility = async (thunkAPI) => {
    try {
        const eligibilitySection = await globalService.getEligibilitySection({section_id: 3})

        let eligibility = [...eligibilitySection[0].eligibility_chain, ...eligibilitySection[0].eligibility_product]

        if (eligibility.length) {
            eligibility = await globalService.checkEligibility({data: {section_id: 3}})
        }

        return eligibility
    } catch
        (error) {
        return thunkAPI.rejectWithValue(error?.message || error)
    }
}

const userLockCheck = async (data) => {
    return createFetch(SERVER.MARKET, LOCKER.LOCK_CHECK, "POST", {authorization: true}, {data})
        .then(result => result.data)
        .catch(error => {
            errorHandler(error?.message ?? error)
        })
}

const userKonvertLock = async (data) => {
    return createFetch(SERVER.MARKET, LOCKER.KONVERT_LOCK, "POST", {authorization: true}, {data})
        .then(result => result.data)
        .catch(error => {
            errorHandler(error?.message ?? error)
        })
}

const getInventoryTokenIdBySku = async (data) => {
    return createFetch(SERVER.MARKET, LOCKER.INVENTORY_ID, "POST", {authorization: true}, {data: data})
        .then(result => result.data)
        .catch(error => {
            errorHandler(error?.message ?? error)
        })
}

const getUsersSockets = async (data) => {
    return createFetch(SERVER.MARKET, LOCKER.USER_SOCKETS, "POST", {authorization: false}, data)
        .then(result => result.data)
        .catch(error => {
            errorHandler(error?.message ?? error)
        })
}

const getUserInventory = async (data) => {
    return fetch(`${ENVIRONMENTS.XSOLLA_STORE_TARGET_SERVER}/${XSOLLA_STORE.GET_INVENTORIES(ENVIRONMENTS.XSOLLA_PROJECT_ID, data)}`, {
        method: 'GET',
        headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken")
        }
    })
        .then(async (response) => {
            let res = await response.json()

            return {items: res.items, status: response.status}
        })
}

const listCurrentCosmetic = async (data, thunkAPI) => {
    return createFetch(SERVER.MARKET, LOCKER.CREATE_ORDER, "POST", {authorization: true}, {data})
        .then(result => result.data)
        .catch(error => thunkAPI.rejectWithValue(error?.message || error))
}

const getUserListedCurrentCosmetic = async (data, thunkAPI) => {
    return createFetch(SERVER.MARKET, LOCKER.LISTED_ORDER, "POST", {authorization: false}, data)
        .then(result => {
            const inventoryWithOrder = result.data
            const isString = typeof inventoryWithOrder.order === "string"

            if (isString) inventoryWithOrder.order = JSON.parse(inventoryWithOrder.order)

            return inventoryWithOrder
        })
        .catch(error => thunkAPI.rejectWithValue(error?.message || error))
}

const getCurrentCosmetic = async (data, thunkAPI) => {
    return createFetch(SERVER.MARKET, LOCKER.INVENTORY, "POST", {}, data)
        .then(result => result.data)
        .catch(error => thunkAPI.rejectWithValue(error?.message || error))
}

export const lockerService = {
    userLockCheck,
    getUsersSockets,
    userKonvertLock,
    getUserInventory,
    getCurrentCosmetic,
    getUserEligibility,
    listCurrentCosmetic,
    getInventoryTokenIdBySku,
    getUserListedCurrentCosmetic,
}