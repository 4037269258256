import React from "react";

import ProgressiveImage from "components/atoms/ProgressiveImage";
import UnderLine from "components/atoms/UnderLine";
import Button from "components/atoms/Button";

import {png} from "assets/png";
import "./style.scss"

const HowToBuyTokenOne = () => (
    <section className="how-to-buy-token_one">
        <div className="how-to-buy-token_one_content container">
            <div className="how-to-buy-token_one_content_left-part">
                <h1>How to <br/><span>Buy kompete token</span></h1>
                <UnderLine/>
                <p>Looking to buy KOMPETE Token but aren’t sure how? We’ve made it easy. Follow these three simple
                    steps to secure your bag and maximize your fun!</p>
                <div className="how-to-buy-token_one_content_left-part_buttons">
                    <Button
                        onClick={() => window.open("https://discord.gg/kompete-921684972786573333", "_blank")}
                        view="secondary" icon="discordBlack"
                        path={true}
                    >
                        Get help in discord
                    </Button>
                    <Button
                        onClick={() => window.open("https://t.me/KOMPETEgameportal", "_blank")}
                        view="secondary"
                        icon="telegramBlack"
                        path={true}>
                        Get help in telegram
                    </Button>
                </div>
            </div>
            <div className="how-to-buy-token_one_content_right-part">
                <ProgressiveImage alt="kompete-token" imgSrc={png.T1Png} previewSrc={png.T1Webp}/>
            </div>
        </div>
    </section>
)

export default HowToBuyTokenOne