import React from "react"

import {Icon, UnderLine} from "components/atoms";

import "./style.scss"

const HowToBuyTokenFour = () => (
    <section className="how-to-buy-token_four">
        <div className="container how-to-buy-token_four_block">
            <h2 className="how-to-buy-token_four_block_title">Need
                Help? <span>connect with <br/> us on socials!</span>
            </h2>
            <UnderLine/>
            <div className="container how-to-buy-token_four_block_socials">
                <div>
                    <a name="telegram" href="https://t.me/KOMPETEgameportal" target="_blank" rel="noreferrer">
                        <Icon name="telegramWhite"/>
                        <span>Telegram</span>
                    </a>
                </div>
                <div>
                    <a name="discord" href="https://discord.gg/kompete-921684972786573333" target="_blank"
                       rel="noreferrer">
                        <Icon name="discordBlue"/>
                        <span>Discord</span>
                    </a>
                </div>
                <div>
                    <a name="twitter" href="https://twitter.com/KOMPETEgame" target="_blank" rel="noreferrer">
                        <Icon name="twitterXWhite"/>
                        <span>X</span>
                    </a>
                </div>
            </div>
        </div>
    </section>
)

export default HowToBuyTokenFour