import React from "react";
import classNames from "classnames";
import {useCountdownTimer} from "hooks";

const LimitedTimeCountDown = ({targetDateTime, isLoading}) => {
    const {countdown} = useCountdownTimer(targetDateTime, true)
    const timeStyle = classNames("shop-limited_content_countDown", {"skeleton": isLoading})

    if (targetDateTime && countdown?.minutes === "00" && countdown?.seconds === "00") window.location.reload()

    return (
        <div className={timeStyle}>
            <div className="shop-limited_content_countDown_block">
                {!isLoading && countdown &&
                    <React.Fragment>
                        <h4 className="shop-limited_content_countDown_block_open">
                            <TimeItem countdown={countdown.days} time="days"/>
                            <TimeItem countdown={countdown.hours} time="hours"/>
                            <TimeItem countdown={countdown.minutes} time="minutes"/>
                            <TimeItem countdown={countdown.seconds} time="seconds"/>
                        </h4>
                    </React.Fragment>}
            </div>
        </div>
    )
}

export default LimitedTimeCountDown

const TimeItem = ({countdown, time}) => (
    <div>
        {time === "days" && <span> Time left: </span>}
        {countdown !== "00" ? (
            <React.Fragment>
                <span className="time"> {countdown} </span>
                <span> {time}{time !== "seconds" && ","}</span>
            </React.Fragment>
        ) : (
            time === "seconds" && (
                <React.Fragment>
                    <span className="time"> {countdown} </span>
                    <span> {time}</span>
                </React.Fragment>
            )
        )}
    </div>
);