import {useLocation} from "react-router-dom"
import React from "react";

import HowToBuyTokenThree from "components/organisms/Token/HowToBuyKompeteToken/HowToBuyTokenThree";
import HowToBuyTokenFour from "components/organisms/Token/HowToBuyKompeteToken/HowToBuyTokenFour";
import HowToBuyTokenOne from "components/organisms/Token/HowToBuyKompeteToken/HowToBuyTokenOne";
import HowToBuyTokenTwo from "components/organisms/Token/HowToBuyKompeteToken/HowToBuyTokenTwo";
import TokenNine from "components/organisms/Token/TokenNine";
import DefaultLayout from "layouts/DefaultLayout";
import Seo from "components/atoms/Seo";

const BuyKompeteToken = () => {
    const location = useLocation()
    const isBnb = location.pathname === "/bnb"

    return (
        <DefaultLayout menu={true} isFollowUsShow={true}>
            <Seo
                title={isBnb ? "Buy KOMPETE with BNB - KOMPETE" : "Buy KOMPETE with ETH - kOMPETE"}
                description={
                    isBnb
                        ? "Looking to buy KOMPETE Token with BNB, but aren’t sure how? We’ve made it easy. Follow these " +
                        "three simple steps to secure your bag and maximize your fun!"
                        : "Looking to buy KOMPETE Token with Ethereum, but aren’t sure how? We’ve made it easy. Follow " +
                        "these three simple steps to secure your bag and maximize your fun!"
                }
            />
            <HowToBuyTokenOne/>
            <HowToBuyTokenTwo/>
            <HowToBuyTokenThree/>
            <HowToBuyTokenFour/>
            <TokenNine/>
        </DefaultLayout>
    )
}

export default BuyKompeteToken