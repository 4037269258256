import {BrowserRouter as Router, Navigate, Route, Routes,} from "react-router-dom";
import {useDispatch} from "react-redux";
import React, {useEffect} from "react";

import {BANNER, CHAINS_ENVIRONMENT, SUPPORTED_CHAINS} from "./utils";
import {useAuthorization, useConnectedWallet} from "./hooks";
import {switchCurrentChain} from "./store/Web3/web3Slice";
import {setBalance} from "./store/AuthV2/AuthV2Slice";

import BazaarCurrentAlreadyListedWeb3Cosmetic from "./pages/Bazaar/BazaarCurrentCosmetic/BazaarCurrentAlreadyListedWeb3Cosmetic";
import {LockerCurrentWeb2Cosmetic, LockerCurrentNotListedWeb3Cosmetic,} from "./pages/Locker/LockerCurrentCosmetic";
import GlobalModals from "./components/organisms/GlobalModals";
import TermsConditions from "./pages/TermsConditions";
import BuyKompeteToken from "./pages/Token/HowToBuy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import NewsPost from "./pages/News/NewsPosts";
import Success from "./pages/LP/Success";
import FreeItem from "./pages/FreeItem";
import Download from "./pages/Download";
import Option from "./pages/LP/Option";
import PageNotFound from "./pages/404";
import Profile from "./pages/Profile";
import Locker from "./pages/Locker";
import Delete from "./pages/Delete";
import Bazaar from "./pages/Bazaar";
import Links from "./pages/Links";
import Home from "./pages/Home";
import News from "./pages/News";
import Shop from "./pages/Shop";
import Deck from "./pages/Deck";

const Routers = () => {
    const {connectedChainId, getEthBalance, getKompeteBalance, address} = useConnectedWallet()
    const {isAuth} = useAuthorization()
    const dispatch = useDispatch()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => chainAndBalancesCheck(), [connectedChainId]);

    const chainAndBalancesCheck = () => {
        if (connectedChainId && SUPPORTED_CHAINS[CHAINS_ENVIRONMENT].includes(connectedChainId)) {
            dispatch(switchCurrentChain({chainId: connectedChainId}))
            getKompeteBalance(address, connectedChainId)
            getEthBalance(address, connectedChainId)
        }

        if (isAuth && !connectedChainId) {
            dispatch(setBalance({currencyType: "ethBalance", balance: 0}))
            dispatch(setBalance({currencyType: "kompeteBalance", balance: 0}))
        }
    }

    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<Home/>}/>
                <Route path="/shop" element={<Shop/>}/>
                <Route path="/news" element={<News/>}/>
                <Route path="/links" element={<Links/>}/>
                <Route path="/pitch" element={<Deck/>}/>
                <Route path="/bazaar" element={<Bazaar/>}/>
                <Route path="/locker" element={<Locker/>}/>
                <Route path="/delete" element={<Delete/>}/>
                <Route path="*" element={<PageNotFound/>}/>
                <Route path="/lp/pc-02" element={<Option/>}/>
                <Route path="/profile" element={<Profile/>}/>
                <Route path="/news/:id" element={<NewsPost/>}/>
                <Route path="/lp/pc-01" element={<Download/>}/>
                <Route path="/download" element={<Download/>}/>
                <Route path="/free/item" element={<FreeItem/>}/>
                <Route path="/lp/mobile-02" element={<Option/>}/>
                <Route path="/bnb" element={<BuyKompeteToken/>}/>
                <Route path="/lp/mobile-01" element={<Download/>}/>
                <Route path="/terms" element={<TermsConditions/>}/>
                <Route path="/privacy" element={<PrivacyPolicy/>}/>
                <Route path="/lp/pc-02-success" element={<Success/>}/>
                <Route path="/lp/mobile-02-success" element={<Success/>}/>
                <Route exact path="/download" element={<Navigate to="/" replace />} />
                <Route exact path="/token" element={<Navigate to="/bazaar?tab=token" replace />} />
                <Route path="/lp/download" element={<Download banner={BANNER.DISCORD}/>}/>
                <Route path="/locker/inventory/:sku" element={<LockerCurrentWeb2Cosmetic/>}/>
                <Route path="/locker/inventory/:sku/:token_id" element={<LockerCurrentNotListedWeb3Cosmetic/>}/>
                <Route path="/bazaar/inventory/:sku/:token_id/:order_id"
                       element={<BazaarCurrentAlreadyListedWeb3Cosmetic/>}/>
            </Routes>
            <GlobalModals/>
        </Router>
    )
}

export default Routers