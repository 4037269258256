import React, {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";

import {useDetectMobile} from "hooks";
import {BAZAAR_TABS} from "utils";

import {BazaarCosmetics} from "components/organisms/Bazaar";
import {DefaultLayout} from "layouts";

const Bazaar = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    const currentTab = searchParams.get("tab") !== null ? searchParams.get("tab") : BAZAAR_TABS.ITEMS
    const [activeTab, setActiveTab] = useState(currentTab);
    const {isMobile} = useDetectMobile()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => changeTab(currentTab), [searchParams])

    const changeTab = (currentTab) => {
        if (isMobile) window.scrollTo({top: 0, behavior: 'smooth'})

        setActiveTab(currentTab)
        setSearchParams(new URLSearchParams({tab: currentTab}))
    }

    return (
        <DefaultLayout isFollowUsBack={true}>
            <BazaarCosmetics activeTab={activeTab} changeTab={changeTab}/>
        </DefaultLayout>
    )
}

export default Bazaar