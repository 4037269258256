import React, {useEffect, useRef} from "react"

import {tabOptions, ERROR, SUCCESS, LOCKER_TABS, GLOBAL_MODALS, WALLET_ERROR_CODE, errorHandler} from "utils"
import {useConnectedWallet, useConnectWallet, useLocalStorage, useUtils,} from "hooks";
import {useCheckListedOrdersExpirationMutation} from "store/Bazaar/bazaar.api";
import {useGlobalModalsContext} from "layouts";

import {TabDynamicBlock, TabContent} from "components/molecules";
import LockerListedCosmetics from "./LockerListedCosmetics";
import LockerOwnedCosmetics from "./LockerOwnedCosmetics";
import LockerConvert from "./Konvert/LockerConvert";
import LockerActivity from "./LockerActivity";

const LockerCosmetics = ({activeTab, changeTab, lockerCosmeticRef}) => {
    const {isConnected, isWalletConnection, isConnectedAddressCorrect} = useConnectedWallet()
    const {setModal, setMessage, resetStatesAndClose} = useGlobalModalsContext()
    const {setWalletConnectionSuccess} = useLocalStorage()
    const {disconnectWallet} = useConnectWallet()
    const {switchChain, addChain} = useUtils()

    const [checkOrdersExpiration] = useCheckListedOrdersExpirationMutation()

    const lockerTabRef = useRef(null)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => checkOrdersExpiration(), [])

    useEffect(() => checkWalletConnectionAndAddressMatch(), [isConnected]);

    const checkWalletConnectionAndAddressMatch = async () => {
        if (isConnected && !isWalletConnection) {
            try {
                setModal(GLOBAL_MODALS.PROCESS)

                const {error, code} = await switchChain()

                if (error && code !== WALLET_ERROR_CODE.UNRECOGNIZED_CHAIN_ID) errorHandler(ERROR.CLEAN)

                if (error && code === WALLET_ERROR_CODE.UNRECOGNIZED_CHAIN_ID) {
                    const {error} = await addChain()

                    if (error) errorHandler(ERROR.CLEAN)
                }

                if (!isConnectedAddressCorrect) {
                    setModal(GLOBAL_MODALS.ERROR)
                    setMessage(ERROR.WALLET_CONNECTED_MATCH_INVALID)

                    resetStatesAndClose()
                    disconnectWallet()
                } else {
                    setModal(GLOBAL_MODALS.SUCCESS)
                    setMessage(SUCCESS.WALLET_CONNECTED)
                    resetStatesAndClose()
                    setWalletConnectionSuccess()
                }
            } catch (error) {
                setModal(GLOBAL_MODALS.ERROR)
                setMessage(ERROR.USER_DENIED_SIGNATURE)

                resetStatesAndClose()
                disconnectWallet()
            }
        }
    }

    return (
        <section className="locker_cosmetics" ref={lockerCosmeticRef}>
            <div className="locker_cosmetics_tabs" ref={lockerTabRef}>
                <TabDynamicBlock
                    options={tabOptions}
                    activeTab={activeTab}
                    isSetUrlParams={false}
                    setActiveTab={changeTab}
                />
            </div>
            <div className="locker_cosmetics_content">
                <div className="locker_cosmetics_content_general">
                    <TabContent id={LOCKER_TABS.OWNED} activeTab={activeTab}>
                        <LockerOwnedCosmetics parentSectionRef={lockerCosmeticRef}/>
                    </TabContent>
                    <TabContent id={LOCKER_TABS.LISTED} activeTab={activeTab}>
                        <LockerListedCosmetics/>
                    </TabContent>
                    <TabContent id={LOCKER_TABS.ACTIVITY} activeTab={activeTab}>
                        <LockerActivity/>
                    </TabContent>
                    <TabContent id={LOCKER_TABS.KONVERT} activeTab={activeTab}>
                        <LockerConvert/>
                    </TabContent>
                </div>
            </div>
        </section>
    )
}

export default LockerCosmetics