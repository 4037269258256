import React, {useEffect} from "react";
import {useDispatch} from "react-redux";

import {reset} from "store/Locker/lockerSlice";

import LockerConvertEligibility from "./LockerConvertEligibility";
import LockerConvertSelector from "./LockerConvertSelector";

import "../style.scss"

const LockerConvert = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        return () => {
            dispatch(reset({section: "konvert_eligibility", defaultData: new Array(3).fill("")}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="locker_cosmetics_content_general_konvert">
            <LockerConvertEligibility/>
            <LockerConvertSelector/>
        </div>
    )
}

export default LockerConvert