import React, {useEffect, useRef, useState} from "react";
import {useSearchParams} from "react-router-dom";

import {ModalsProvider} from "layouts";
import {LOCKER_TABS} from "utils";

import LockerCosmetics from "components/organisms/Locker/LockerCosmetics";
import LockerSteps from "components/organisms/Locker/LockerSteps";
import ProtectedPage from "components/molecules/ProtectedPage";
import DefaultLayout from "layouts/DefaultLayout";

const Locker = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const lockerCosmeticRef = useRef()

    const currentTab = searchParams.get("tab") !== null ? searchParams.get("tab") : LOCKER_TABS.OWNED
    const [activeTab, setActiveTab] = useState(currentTab);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => changeTab(currentTab), [])

    const changeTab = (currentTab) => {
        setActiveTab(currentTab)
        setSearchParams(new URLSearchParams({tab: currentTab}))
    }

    return (
        <DefaultLayout>
            <ProtectedPage>
                <ModalsProvider>
                    <LockerCosmetics
                        activeTab={activeTab}
                        changeTab={changeTab}
                        lockerCosmeticRef={lockerCosmeticRef}
                    />
                    <LockerSteps changeTab={changeTab}/>
                </ModalsProvider>
            </ProtectedPage>
        </DefaultLayout>
    )
}

export default Locker